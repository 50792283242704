<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script >

</script>

<style>
/* Estilos para el componente App */
</style>
