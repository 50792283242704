<template>
  <MainLayout>
    <div>
      <h2>Iniciar sesión</h2>
      <form @submit="login">
        <input class=" p-2 border border-gray-300 rounded" type="text" v-model="username" placeholder="Nombre de usuario" required>
        <input class=" p-2 border border-gray-300 rounded" type="password" v-model="password" placeholder="Contraseña" required>
        <button class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" type="submit">Iniciar sesión</button>
      </form>
    </div>
  </MainLayout>
  </template>
  
  <script>
  import axios from 'axios';
  import MainLayout from './../components/Layout.vue';
  export default {
    created() {
    const token = localStorage.getItem('token');
    if (token) {
      // El token existe, redirige al usuario a otra página
      this.$router.push('/');
    }
  },
    name: 'LoginPage',
    components:{
      MainLayout
    },
    data() {
      return {
        username: 'admin.blog@gmail.com',
        password: 'demo12345'
      };
    },
    methods: {
      login(event) {
        event.preventDefault();
  
        // Envía la solicitud de inicio de sesión al servidor
        axios.post(process.env.VUE_APP_API_URL+'/api/login', {
          email: this.username,
          password: this.password
        })
        .then(response => {
          // Guarda el token de autenticación en el almacenamiento local (localStorage) o en las cookies
          const token = response.data.token;
          localStorage.setItem('token', token);
  
          // Redirige a la ruta protegida
          this.$router.push('/');
        })
        .catch(error => {
          console.error(error);
          // Manejo de errores de inicio de sesión
        });
      }
    }
  };
  </script>
  