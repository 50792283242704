<template>
  <MainLayout>
    <div class="flex justify-between items-center mb-6">
      <h1 class="text-2xl font-bold">Publicaciones</h1>
      <Button label="Agregar publicación" severity="info" size="small" @click="openForm()" />
    </div>
    <DataTable :value="newsAll" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" class="w-full">
      <Column field="id" header="ID" style="width: 25%"></Column>
      <Column field="title" header="Título" style="width: 25%"></Column>
      <Column field="publication_date" header="Fecha de publicación" style="width: 25%">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.publication_date) }}
        </template>
      </Column>
      <Column header="Image">
        <template #body="slotProps">
          <img :src="`${url}/${slotProps.data.image}?v=${slotProps.data.updated_at}`" :alt="slotProps.data.image"
            class="w-6rem shadow-2 border-round" />
        </template>
      </Column>
      <Column headerStyle="width:4rem">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" severity="success" @click="openForm(slotProps.data)" />
          <Button icon="pi pi-trash" severity="danger" />
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="modal" modal header="Publicación" class="w-full md:w-10/12 z-10">
      <div class="flex flex-wrap items-end">

        <div class="flex flex-col gap-2 mb-4 w-full md:w-1/2 md:pr-2">
          <label for="news-title" class="font-semibold">Título</label>
          <InputText id="news-title" v-model="news.title" :maxlength="180" />
          <p v-if="validate.title" class="text-red-600 font-semibold">Es requerido</p>
        </div>
        <div class="flex flex-col gap-2 mb-4 w-full md:w-1/2 md:pl-2">
          <label for="publication_date" class="font-semibold">Fecha de publicación</label>
          <Calendar id="publication_date" v-model="news.publication_date" dateFormat="dd/mm/yy" />
          <p v-if="validate.publication_date" class="text-red-600 font-semibold">Es requerido</p>
        </div>
        <div class="flex flex-col gap-2 mb-4 w-full md:w-1/2 md:pr-2">
          <label for="meta_description" class="font-semibold">Meta Descripción</label>
          <InputText id="meta_description" v-model="news.meta_description" :maxlength="250" />
        </div>
        <div class="flex flex-col gap-2 mb-4 w-full md:w-1/2 md:pr-2">
          <label for="meta_keywords" class="font-semibold">Meta palabras</label>
          <InputText id="meta_keywords" v-model="news.meta_keywords" :maxlength="250" />
        </div>
        <div class="flex flex-col gap-2 mb-4 w-full md:w-1/2 md:pl-2">
          <label for="news-title" class="font-semibold">Portada</label>
          <ImageCroppie v-model:image="news.imagePreview"
            :imageOrigin="news.image == '' ? '' : `${url}/${news.image}?v=${news.updated_at}`" inputLabel="imagen"
            ref="refImgReward" pronoun="la" :aspectRatio="{ height: 1, width: 2, }"></ImageCroppie>
          <p v-if="validate.image" class="text-red-600 font-semibold">Es requerido</p>
        </div>
        <div class="flex flex-col gap-2 mb-4 w-full md:w-1/2 md:pl-2">
          <label for="news-title" class="font-semibold">Miniatura</label>
          <ImageCroppie v-model:image="news.image_miniaturaPreview"
            :imageOrigin="news.image_miniatura == '' ? '' : `${url}/${news.image_miniatura}?v=${news.updated_at}`"
            inputLabel="imagen" ref="refImgReward" pronoun="la" :aspectRatio="{ height: 5, width: 4, }"></ImageCroppie>
          <p v-if="validate.image" class="text-red-600 font-semibold">Es requerido</p>
        </div>
        <div class="w-full">
          <label class="font-semibold mb-2">Publicaciones relacionadas</label>
          <MultiSelect v-model="news.relacionados" :options="newsAll" optionLabel="title" placeholder="Seleeciona las publicaciones" :maxSelectedLabels="1" class="w-full" filter optionValue="id"/>
          <label class="font-semibold mb-2">Contenido</label>
          <ckeditor :editor="editor" v-model="news.content" :config="editorConfig"></ckeditor>
          <p v-if="validate.content" class="text-red-600 font-semibold">Es requerido</p>
        </div>
        
      </div>
      <div class="flex justify-end">
        <Button v-if="news.id == null" class="mt-3" severity="success" size="small" @click="save()">Guardar</Button>
        <Button v-if="news.id != null" class="mt-3" severity="success" size="small" @click="save(false)">Actualizar</Button>
      </div>
    </Dialog>
    <Toast ref="toast" />
  </MainLayout>
</template>

<script>
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MainLayout from './../components/Layout.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import axios from 'axios';
import ImageCroppie from '../components/ImageCroppie.vue';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
export default {
  name: 'NewsPage',
  components: {
    ImageCroppie,
    MainLayout,
    DataTable,
    Column,
    Dialog,
    Button,
    InputText,
    Calendar,
    MultiSelect,
    Toast
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      editor: ClassicEditor,
      editorConfig: {
        alignment: {
          options: ['left', 'right']
        },
        toolbar: [
          'undo', 'redo',
          '|', 'heading', 'alignment',
          '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
          '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
          '|', 'link',
          '|', 'bulletedList', 'numberedList', 'todoList',
        ],
      },
      modal: false,
      displayCrop: false,
      newsAll: [],
      news: {
        id: null,
        title: '',
        image: '',
        imagePreview: '',
        image_miniatura: '',
        image_miniaturaPreview: '',
        publication_date: '',
        meta_description: '',
        meta_keywords: '',
        content: '',
        updated_at: '',
        relacionados:[]
      },
      validate: {
        title: false,
        imagePreview: false,
        image_miniaturaPreview: false,
        publication_date: false,
        content: false,
        meta_description: false,
        meta_keywords: false,
      },
      imageCrop: '',
      vFile: Math.floor(Math.random() * 10000),
    };
  },
  methods: {
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    rotateImage(grade = 90) {
      this.$refs.cropper.rotate(grade);
    },
    uploadImage(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        if (input.files[0].size / 1024 / 1024 > 2) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El archivo pesa más de 5MB!', life: 3000 });
          this.$refs.file.value = '';
          return;
        }
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageCrop = e.target.result;
          this.displayCrop = true;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      let res = result.canvas.toDataURL();
      if (res) {
        this.news.imagePreview = res;
        this.displayCrop = false;

      }
    },
    clearFile() {
      this.imageCrop = '';
      //this.$emit('value', '');
      if (this.$refs.file) this.$refs.file.value = '';
    },
    clearForm() {
      this.clearFile();
      this.news.id = null;
      this.news.title = '';
      this.news.image = '';
      this.news.image_miniatura = '';
      this.news.imagePreview = '';
      this.news.image_miniaturaPreview = '';
      this.news.publication_date = '';
      this.news.content = '';
      this.news.updated_at = '';
      this.news.meta_description= '',
      this.news.meta_keywords= '',
      this.news.relacionados = [];
    },
    openForm(data = null) {
      this.clearForm();
      if (data != null) {
        this.news.id = data.id;
        this.news.title = data.title;
        this.news.image = data.image;
        this.news.image_miniatura = data.image_miniatura;
        this.news.imagePreview = '';
        this.news.image_miniaturaPreview = '';
        this.news.publication_date = new Date(data.publication_date);
        this.news.content = data.content;
        this.news.meta_description = data.meta_description;
        this.news.meta_keywords = data.meta_keywords;
        this.news.updated_at = data.updated_at;
        this.news.relacionados = JSON.parse(data.relacionados);
      }
      this.modal = true;
    },
    getNewFile(file) {
      let base64String = this.news[file];
      const dataArray = base64String.split(",");
      return dataArray.length == 1 ? dataArray[0] : dataArray[1];
    },
    validateForm(isNew) {
      this.validate.title = this.news.title.trim() == '';
      this.validate.imagePreview = isNew ? this.news.imagePreview.trim() == '' : true;
      this.validate.image_miniaturaPreview = isNew ? this.news.image_miniaturaPreview.trim() == '' : true;
      this.validate.publication_date = this.news.publication_date == '';
      this.validate.content = this.news.content.trim() == '';
      for (let key in this.validate) {
        if (!this.validate[key]) {
          return false;
        }
      }
      return true;
    },
    formatDate(date) {
      const fecha = new Date(date);
      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const anio = fecha.getFullYear();
      return `${dia < 10 ? '0' + dia : dia}-${mes < 10 ? '0' + mes : mes}-${anio}`;
    },
    getNews() {
      let self = this;

      axios.get(this.url + '/api/news-all')
        .then(response => {
          self.newsAll = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    save(isNew = true) {
      let self = this;
      if (this.validateForm(isNew)) return false;
      const formData = new FormData();
      formData.append('title', this.news.title.trim());
      formData.append('image', this.getNewFile('imagePreview'));
      formData.append('image_miniatura', this.getNewFile('image_miniaturaPreview'));
      formData.append('publication_date', new Date(this.news.publication_date).toISOString().substring(0, 10));
      formData.append('content', this.news.content);
      formData.append('elementor_builder', this.news.content);
      formData.append('meta_description', this.news.meta_description);
      formData.append('meta_keywords', this.news.meta_keywords);
      formData.append('relacionados', JSON.stringify(this.news.relacionados));

      let id = this.news.id == null ? '' : '/' + this.news.id;
      axios.post(this.url + '/api/news-store' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          response
          self.getNews();
          self.modal = false;
          self.$toast.add({ severity: 'success', summary: 'Publicación', detail: 'Se guardo la publicación.', life: 3000 });

        })
        .catch(error => {
          console.error(error);
          self.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se guardo la publicación.', life: 3000 });
          // Aquí puedes manejar los errores en caso de que ocurran
        });
    }
  },
  mounted() {
    this.getNews();

  }
}
</script>

<style scoped>
:deep(.cropper) {
  height: 600px;
  width: 600px;
  max-width: 100%;
  max-height: 50vh;
  background: #DDD;
}</style>
