//import router from './router';
import axios from 'axios';

export async function authMiddleware(to, from, next) {
  const token = localStorage.getItem('token');

  if (!token) {
    // No hay token, redirige al usuario a la página de inicio de sesión
    next('/login');
  } else {
    try {
      // Hacer una llamada a la API para verificar el token
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      await axios.get(process.env.VUE_APP_API_URL + '/api/user', {})
        .then(response => {
          console.log(response)
          next();
        })
        .catch(error => {
          console.log(error)
          localStorage.removeItem('token');
          next('/login');
        })

    } catch (error) {
      localStorage.removeItem('token');
      next('/login');
    }
    next();
  }
}
