import { createRouter, createWebHistory } from 'vue-router';
import { authMiddleware } from './authMiddleware';
import NewsPage from './pages/NewsPage.vue';
import LoginPage from './pages/LoginPage.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/',
    name: 'News',
    component: NewsPage,
    meta: { requiresAuth: true },
    beforeEnter: authMiddleware
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
