<template>
  <div>
    <div class="flex flex-row flex-wrap items-center gap-4">
      <Image v-if="image == '' && imageOrigin != ''" :src="imageOrigin" :alt="inputLabel" width="100" loading="lazy"
        preview />
      <Image v-if="image != ''" :src="image" :alt="inputLabel" width="100" loading="lazy" preview />
      <Button v-if="image" icon="pi pi-trash" class="p-button-rounded p-button-danger destroy-selected"
        @click="clearFile()" />
    </div>
    <div class="flex align-items-center justify-content-center mt-2">
      <div class="file-button">
        <label :class="(image == '' ? 'p-button-info' : 'p-button-secondary') + ' p-button p-component p-button-sm'"
          @click="$refs.file.image = ''">
          <i class="pi pi-image"></i>
          <span class="hidden md:inline-flex">&nbsp;{{ image == '' ? ('Seleciona ' + pronoun + ' ' + inputLabel) :
            ('Cambiar ' + pronoun + ' ' + inputLabel + ' seleccionad' + (pronoun == 'el' ? 'o' : 'a')) }}</span>
          <input type="file" ref="file" @change="uploadImage($event)" accept="image/*" style="display: none;" />
        </label>

      </div>
    </div>
    <p class="input-instructions">Formato jpg o png
      que no
      exceda los 2MB</p>
    <Dialog class="cropDialog" :header="inputLabel.replace(/^\w/, (c) => c.toUpperCase())" v-model:visible="displayCrop"
      :modal="true">
      <div class="example-wrapper">
        <Cropper ref="cropper" class="cropper" :src="imageCrop"
          :stencilProps="{ aspectRatio: aspectRatio.width / aspectRatio.height, movable: true, resizable: true }"
          :options="options" />
        <div class="vertical-buttons">
          <div class="square-button save-crop" @click="cropImage()" style="display: none;">
            <span class="pi pi-save"></span>
          </div>
          <div class="square-button" @click="rotateImage(-90)">
            <span class="pi pi-directions-alt"></span>
          </div>
          <div class="square-button" @click="rotateImage()">
            <span class="pi pi-directions"></span>
          </div>
          <div class="square-button" @click="zoom(1.5)">
            <span class="pi pi-plus-circle"></span>
          </div>
          <div class="square-button" @click="zoom(0.5)">
            <span class="pi pi-minus-circle"></span>
          </div>
        </div>
        <button class="p-button-success p-button p-component mt-2" type="button" @click="cropImage()">Guardar</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Image from 'primevue/image';
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ImageCroppie",
  components: {
    Cropper,
    Button,
    Dialog,
    Image,
  },
  props: {
    modoEditor: {
      type: Boolean,
      default: false,
    },
    pronoun: {
      type: String,
      default: 'el',
    },
    inputLabel: {
      type: String,
      default: 'archivo',
    },
    imageOrigin: {
      type: String,
      default: '',
    },
    stencilComponent: {
      type: String,
      default: 'rectangle-stencil',
      // default:'circle-stencil'
    },

    aspectRatio: {
      type: Object,
      default() {
        return {
          height: 1,
          width: 1,
        };
      },
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: '',
      imageCrop: '',
      displayCrop: false,
      options: {
        maxHeight: 600, // ajusta la altura máxima deseada
      },
    };
  },
  mounted() {
  },
  methods: {
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    rotateImage(grade = 90) {
      this.$refs.cropper.rotate(grade);
    },
    uploadImage(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        if (input.files[0].size / 1024 / 1024 > 2) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El archivo pesa más de 5MB!', life: 3000 });
          this.$refs.file.value = '';
          return;
        }
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageCrop = e.target.result;
          this.displayCrop = true;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      let res = result.canvas.toDataURL();
      if (res) {
        this.image = res;
        this.$emit('update:image', res);
        this.displayCrop = false;
      }
    },
    clearFile() {
      this.image = '';
      this.$emit('update:image', '');
      if (this.$refs.file) this.$refs.file.value = '';
    },
    getNewFile() {
      let base64String = this.value;
      const decodedData = base64String.split(",");
      const byteCharacters = atob(decodedData.length == 1 ? decodedData[0] : decodedData[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: 'image/png' });
    },
  },
  watch: {
    value(newValue) {
      this.image = newValue;
    },
    internalValue(newValue) {
      this.$emit('update:value', newValue);
    },
  },
};
</script>
<style scoped>
.cropDialog ::v-deep(.p-dialog) {
  max-width: 90vw;
  max-height: 80vh;
}

::v-deep(.cropper) {
  height: 600px;
  width: 600px;
  max-width: 100%;
  max-height: 50vh;
  background: #DDD;
}

.vertical-buttons {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
}

.square-button span {
  color: white;
}

.square-button {
  background: rgba(black, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background 0.5s;

}

.square-button:hover {
  background: white;
}

.square-button:hover span {
  color: black;
}

.save-crop,
.save-crop:hover {
  background-color: rgb(25, 161, 25);
}

.save-crop:hover span {
  color: white;
}

.input-instructions {
  font-style: italic;
  color: grey !important;
  font-weight: 600;
  width: 100%;
}</style>