import { createApp } from 'vue'
import router from './router';
import App from './App.vue'
import 'tailwindcss/tailwind.css'
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css";     
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';
import CKEditor from '@ckeditor/ckeditor5-vue';
import './styles/index.css'

const app= createApp(App)
app.use(PrimeVue);
app.use(CKEditor);
app.use(router);
app.use(ToastService);
app.mount('#app')